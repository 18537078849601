<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="30">
        <v-card ref="form" justify="center">
          <v-list-item-title class="text-h4 text-center pt-5 mb-5 ">
            <b>{{ $t(`caption['Authentication_Title']`) }}</b>
          </v-list-item-title>

          <v-list-item class="text-h7 text-left ml-7 mb-10">
            {{ $t(`caption['Authentication_SendAuthenticationCodeMessage']`) }}<br>{{
                $t(`caption['Authentication_InputAuthenticationCodeMessage']`)
            }}
          </v-list-item>
          <div class="ma-auto position-relative" style="max-width: 600px">
            <v-otp-input class="ml-10 mr-10" length="6" height="65px" type="number" v-model="otp" @finish="onFinish"
              @input="checkAuthCodeDigit">
            </v-otp-input>
          </div>
          <v-row class="mt-8 position-relative" justify="center">
            <v-btn class="mb-5" v-bind:disabled="isDisabled" width="200px" height="50px" depressed @click="submit"
              color="primary" elevation="5" x-large>{{ $t(`caption['Common_Login']`) }}</v-btn>
          </v-row>
          <v-row justify="center" class="pb-10">
            <u><a class="mb-12" @click="callResendAPI">{{ $t(`caption['Authentication_Resend']`) }}</a></u>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import domain from '@/domain'
import axios from 'axios'
import Vue from 'vue'
import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)
export default {
  data: () => ({
    id: sessionStorage.getItem("passID"),
    email: sessionStorage.getItem("passEmail"),
    date: sessionStorage.getItem("passDate"),
    language: sessionStorage.getItem("passLang"),
    otp: '',
    text: '',
    status: "",
    errorCode: "",
    isDisabled: true,
    uuid: "",
    DOMAIN_URL: domain.DOMAIN_URL
  }),
  methods: {
    onFinish() {
      this.callAuthAPI()
    },
    submit() {
      this.callAuthAPI()
    },
    callAuthAPI() {
      this.$emit("apiConnect", true);
            const postData = {
                "name": this.id,
                "authenticationCode": this.otp
            }
            const options = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
      axios.post(this.DOMAIN_URL + "/auth/authentication", postData, options)
        .then(response => {
          this.status = response.status
          this.$emit("apiConnect", false);
          if (this.status == 200) {
            this.uuid = response.data.uuid
            sessionStorage.setItem("passUUID", this.uuid)
            sessionStorage.removeItem("passLang")
            this.$router.push('/RemittanceDetailView');
          }
        }).catch(err => {
          this.$emit("apiConnect", false);
          if (err.response.data) {
            this.errorCode = err.response.data.errorCode
            switch (this.errorCode) {
              case 400001:
                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 400002:
                this.$alert(this.$t(`caption['Common_InputErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 401001:
                this.$alert(this.$t(`caption['Common_AuthenticationErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 500001:
                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              default:
            }
          } else {
            this.$alert(this.$t(`caption['Common_NetworkErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error", { "html": "<div style=\"white-space: pre-line\">" + this.$t(`caption['Common_NetworkErrorMessage']`) + "</div>" })
          }
        });
    },
    checkAuthCodeDigit(event) {
      this.isDisabled = true
      if (event.length == 6) {
        this.isDisabled = false
      }
    },
    callResendAPI() {
      this.$emit("apiConnect", true);

      const postData = {
          "name": this.id,
          "personBirthdate": this.date,
          "mailAddress1": this.email,
          "language": this.language
      }
      const options = {
          headers: {
              "Content-Type": "application/json"
          }
      }
      axios.post(this.DOMAIN_URL + "/auth/resend", postData, options)
        .then(() => {
          this.$emit("apiConnect", false);
        }).catch(err => {
          this.$emit("apiConnect", false);
          if (err.response.data) {
            this.errorCode = err.response.data.errorCode
            switch (this.errorCode) {
              case 400001:
                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 400002:
                this.$alert(this.$t(`caption['Common_InputErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 401001:
                this.$alert(this.$t(`caption['Common_AuthenticationErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              case 500001:
                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error")
                break
              default:
            }
          } else {
            this.$alert(this.$t(`caption['Common_NetworkErrorMessage']`), this.$t(`caption['Authentication_Title']`), "error", { "html": "<div style=\"white-space: pre-line\">" + this.$t(`caption['Common_NetworkErrorMessage']`) + "</div>" })
          }
        });
    }
  }
}

</script>

