<template>
  <v-app>
    <v-card class="overflow-hidden">
      <v-app-bar app color="white">
        <div class="d-flex align-center">
          <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://www.remit.co.jp/img/common/logo.png"
            transition="scale-transition" />
        </div>
      </v-app-bar>
      <v-toolbar extended color="primary">
      </v-toolbar>
    </v-card>
    <v-main>
      <router-view @apiConnect="changeOverlay" />
    </v-main>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    overlay: false,
  }),
  methods: {
    changeOverlay(flg) {
      this.overlay = flg
    }
  }
}
</script>
