import Vue from 'vue'
import Router from 'vue-router'
import LoginView from './components/LoginView'
import RemittanceDetailView from './components/RemittanceDetailView'
import TwoStepAuth from './components/TwoStepAuth'

//
// 他のコンポーネントは省略
//

Vue.use(Router)

export default new Router({
  // デフォルトの挙動では URL に `#` が含まれる.
  // URL から hash を取り除くには `mode:history` を指定する
  mode: 'history',                  
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'LoginView',
      component: LoginView 
    },
    {
      path: '/RemittanceDetailView',
      name: 'RemittanceDetailView',
      component: RemittanceDetailView 
    },
    {
      path: '/TwoStepAuth',
      name: 'TwoStepAuth',
      component: TwoStepAuth
    }
  ]
})