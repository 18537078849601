<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="6">
                <v-card elevation="4" outlined>
                    <v-card-text>
                        <div class="ma-5 text-h6">
                            <v-row><b>{{ $t(`caption['RemittanceItemCreatingOrder_MemberInformation']`) }}</b></v-row>
                        </div>
                        <div class="ma-5">
                            <div class="text-caption">
                                {{$t(`caption['RemittanceItemCreatingOrder_FullName']`)}}
                            </div>
                            {{fullName}}<br />
                            <hr />
                        </div>
                        <div class="ma-5">
                            <div class="text-caption">
                                {{$t(`caption['RemittanceItemCreatingOrder_PostalCode']`)}}
                            </div>
                            {{postalCode}}<br />
                            <hr />
                        </div>
                        <div class="ma-5">
                            <div class="text-caption">
                                {{$t(`caption['RemittanceItemCreatingOrder_Address']`)}}</div>
                            {{address}}<br />
                            <hr />
                        </div>
                        <div class="ma-5">
                            <v-checkbox v-model="checkboxComfirm">
                                <template v-slot:label>
                                    <span style="font-size:14px">{{
                                    $t(`caption['RemittanceItemCreatingOrder_MembersInfoVerified']`)
                                    }}</span>
                                </template>
                            </v-checkbox>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card elevation="4" outlined>
                    <v-card-text>
                        <div class="ma-5 text-h6">
                            <v-row><b>{{ $t(`caption['RemittanceItemCreatingOrder_OrderInformation']`) }}</b></v-row>
                        </div>
                        <div class="ma-5 ml-9 text-subtitle-1">
                            <v-row class="ml-10"><b>{{
                            $t(`caption['RemittanceItemCreatingOrder_RelationshipToTheRecipient']`)
                            }}</b>
                            </v-row>
                        </div>
                        <div>
                            <v-card elevation="3" max-width="430" class="mx-auto mb-8">
                                <v-virtual-scroll :items="items" height="300" item-height="900">
                                    <v-checkbox class="ml-10" v-model="checkbox[0]" @change="onChangeEventCheckboxAll"
                                        value="All" v-bind:label="$t(`caption['RemittanceItemCreatingOrder_All']`)"
                                        hide-details>
                                    </v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[2]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Parents']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[11]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_ParentsInlaw']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[6]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_BrotherOrSister']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[12]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_BrotherOrSisterInlaw']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[4]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_WifeOrHusband']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[5]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_SonOrDaughter']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[3]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Grandparents']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[16]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_UncleOrAunt']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[17]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_NephewOrNiece']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[18]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_OtherRelatives']`)" hide-details>
                                    </v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[8]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Myself']`)" hide-details>
                                    </v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[19]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Fiance']`)" hide-details>
                                    </v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[20]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Ex-wifeOrEx-husband']`)" hide-details>
                                    </v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[9]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_FriendOrAcquaintance']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[13]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_FriendOrAcquaintanceHaveNotMet']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[10]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_CustomerOrMarketer']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[14]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Employee']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[15]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Representative']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[21]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Creditor']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[22]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Debtor']`)"
                                        hide-details></v-checkbox>
                                    <v-checkbox class="ml-10" v-model="checkbox[1]" @change="commonCheckboxChange()"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_Other']`)" hide-details>
                                    </v-checkbox>
                                </v-virtual-scroll>
                            </v-card>
                        </div>
                        <div>
                            <v-menu ref="sdmenu" v-model="sdmenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="sdate" ref="sdate"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_PeriodFrom']`)"
                                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="sdate" :active-picker.sync="activePicker"
                                    @input="onInputChangeEvent"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1950-01-01" @change="save">
                                </v-date-picker>
                            </v-menu>
                            <v-menu ref="edmenu" v-model="edmenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="edate" ref="edate"
                                        v-bind:label="$t(`caption['RemittanceItemCreatingOrder_PeriodTo']`)"
                                        class="mt-5" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                        required>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="edate" :active-picker.sync="activePicker"
                                    @input="onInputChangeEvent"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1950-01-01" @change="save">
                                </v-date-picker>
                            </v-menu>
                        </div>
                    </v-card-text>
                    <v-divider class="mt-12"></v-divider>
                    <v-card-actions>
                        <v-btn text @click="btnCancelClick">
                            {{ $t(`caption['Common_Cancel']`) }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="submit" v-bind:disabled="isDisabled">
                            {{ $t(`caption['Common_OK']`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import domain from '@/domain'
import axios from 'axios'
import Vue from 'vue'
import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

export default {
    name: "RemittanceDetailView",
    data: () => ({
        id: sessionStorage.getItem("passID"),
        uuid: sessionStorage.getItem("passUUID"),
        email: sessionStorage.getItem("passEmail"),
        birthday: sessionStorage.getItem("passDate"),
        fullName: "",
        postalCode: "",
        address: "",
        relation: "",
        relations: "",
        sdate: new Date().getFullYear() + "-01-01",
        edate: "",
        register: "",
        back: "",
        sdmenu: false,
        edmenu: false,
        startdate: "",
        picker: "",
        activePicker: "",
        checkboxComfirm: false,
        checkboxList: "",
        checkbox: [],
        status: "",
        errorCode: "",
        isDisabled: true,
        DOMAIN_URL: domain.DOMAIN_URL
    }),
    watch: {
        sdmenu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        edmenu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    mounted() {
        this.callUserInfoAPI()
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ('00' + (dt.getMonth()+1)).slice(-2);
        var d = ('00' + dt.getDate()).slice(-2);
        this.edate = y + '-' + m + '-' + d;
    },
    methods: {
        save(date) {
            this.$refs.sdmenu.save(date)
            this.$refs.edmenu.save(date)
        },
        submit() {
            if (this.checkedInfoVerifiedCheckbox()) {
                if (this.edate != "" && this.sdate > this.edate) {
                    this.$alert(this.$t(`caption['RemittanceItemCreatingOrder_DateCorrelationErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                } else {
                    this.callDetailAPI()
                }
            }
        },
        callDetailAPI() {
            this.$emit("apiConnect", true);
            this.commonGetCheckboxList()
            this.checkboxList = this.checkboxList.slice(0, -1)
            const postData = {
                "name": this.id,
                "mailAddress1": this.email,
                "personBirthdate": this.birthday,
                "relationDisp": this.checkboxList,
                "from": this.sdate,
                "to": this.edate
            }
            const options = {
                headers: {
                    "Authorization": this.uuid,
                    "Content-Type": "application/json"
                }
            }
            axios.post(this.DOMAIN_URL + "/users/" + this.id + '/remittance/details', postData, options)
                .then(response => {
                    this.status = response.status
                    this.$emit("apiConnect", false);
                    if (this.status == 200) {
                        this.$alert(
                            this.$t(`caption['RemittanceItemCreatingOrder_CompleteMessage']`),
                            this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`),
                            "success"
                        ).then(() => {
                            // 初期化
                            var dt = new Date();
                            var y = dt.getFullYear();
                            var m = ('00' + (dt.getMonth()+1)).slice(-2);
                            var d = ('00' + dt.getDate()).slice(-2);
                            this.edate = y + '-' + m + '-' + d;
                            this.sdate = new Date().getFullYear() + "-01-01";

                            this.checkboxComfirm = false;
                            this.checkbox = []
                        })
                    }
                }).catch(err => {
                    this.$emit("apiConnect", false);
                    if (err.response.data) {
                        this.errorCode = err.response.data.errorCode
                        switch (this.errorCode) {
                            case 400001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 400002:
                                this.$alert(this.$t(`caption['Common_InputErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 400003:
                                this.$alert(this.$t(`caption['RemittanceItemCreatingOrder_DateCorrelationErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 400004:
                                this.$alert(this.$t(`caption['RemittanceItemCreatingOrder_NoDetailsErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 401001:
                                this.$alert(this.$t(`caption['Common_AuthenticationErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error").then(() => {
                                    this.$router.push('/')
                                })
                                break
                            case 500001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            default:
                        }
                    } else {
                        this.$alert(this.$t(`caption['Common_NetworkErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error", { "html": "<div style=\"white-space: pre-line\">" + this.$t(`caption['Common_NetworkErrorMessage']`) + "</div>" })
                    }

                });
        },
        callUserInfoAPI() {
            this.$emit("apiConnect", true);
            const options2 = {
                headers: {
                    "Authorization": this.uuid,
                    "Content-Type": "application/json"
                }
            }
            axios.get(this.DOMAIN_URL + "/users/" + this.id, options2)
                .then(response => {
                    this.status = response.status
                    this.$emit("apiConnect", false);
                    if (this.status == 200) {
                        this.fullName = response.data.fullName
                        this.postalCode = response.data.zipCode
                        this.address = response.data.address
                    }
                }).catch(err => {
                    this.$emit("apiConnect", false);
                    if (err.response.data) {
                        this.errorCode = err.response.data.errorCode
                        switch (this.errorCode) {
                            case 400001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 400002:
                                this.$alert(this.$t(`caption['Common_InputErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            case 401001:
                                this.$alert(this.$t(`caption['Common_AuthenticationErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error").then(() => {
                                    this.$router.push('/')
                                })
                                break
                            case 500001:
                                this.$alert(this.$t(`caption['Common_ServerErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error")
                                break
                            default:
                        }
                    } else {
                        this.$alert(this.$t(`caption['Common_NetworkErrorMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "error", { "html": "<div style=\"white-space: pre-line\">" + this.$t(`caption['Common_NetworkErrorMessage']`) + "</div>" })
                    }
                });
        },
        checkedInfoVerifiedCheckbox() {
            if (!this.checkboxComfirm) {
                this.$confirm(this.$t(`caption['RemittanceItemCreatingOrder_MembersInfoVerifiedDialogMessage']`), this.$t(`caption['RemittanceItemCreatingOrder_OrderInformation']`), "warning").then(() => {
                    this.callDetailAPI()
                    return true
                })
                return false
            }
            return true
        },
        onInputChangeEvent() {
            this.isDisabled = true;
            this.commonGetCheckboxList()
            if (this.checkboxList.length > 0 && (this.sdate.length > 0 || this.edate.length > 0)) {
                this.isDisabled = false
            }
        },
        onChangeEventCheckboxAll(event) {
            (event) ? this.checkboxChange(true) : this.checkboxChange(false)
            this.onInputChangeEvent()
        },
        checkboxChange(checkStatus) {
            for (let i = 1; i < 23; i++) {
                this.checkbox[i] = checkStatus
            }
        },
        commonCheckboxChange() {
            this.onInputChangeEvent()
            this.checkbox[0] = false
        },
        commonGetCheckboxList() {
            this.checkboxList = ""
            for (let k = 1; k < 23; k++) {
                if (this.checkbox[k]) {
                    this.checkboxList += (k < 10) ? "0" + k : k
                    this.checkboxList += ";"
                }
            }
        },
        btnCancelClick() {
            window.scrollTo(0, 0)
            this.$router.push('/')
        }
    },
    computed: {
        items() {
            return Array.from({ length: 1 })
        },
    }

}
</script>
